import * as React from "react";
import PageComponent from "src/components/pages/testimonials";

const Page = ({ location }) => {
  const copy = {
    testimonials: [
      {
        name: `Dr. Michael Goldenhersh`,
        title: `Jerusalem`,
        text: `We started out with the father and continued with the son, Jonathan, who continued the tradition of the father which is that the people are very nice, loyal and trustworthy.`,
        video: `https://fast.wistia.net/embed/iframe/wemokv5joz?videoFoam=true`,
      },
      {
        name: `Zvi Sand`,
        title: `Real Estate Developer, Bet Zait`,
        text: `I was very impressed with his knowledge, his capabilities; every question that I raised to him…he immediately answered.`,
        video: `https://fast.wistia.net/embed/iframe/acqnj3dnzv?videoFoam=true`,
      },
      {
        name: `Charlie Deutsch`,
        location: `General Partner of The Gatesworth`,
        text: `….anybody who’s been around the St. Louis community knows the name Spetner. It’s a tradition in caring and service for St. Louis.`,
        video: `https://fast.wistia.net/embed/iframe/b7ge9zucsd?videoFoam=true`,
      },
    ],
  };
  return (
    <PageComponent
      lang="en"
      location={location}
      copy={copy}
      pageTitle="Testimonials"
    />
  );
};

export default Page;
